<template>
<div class="customize-div">
  <div class="customize-title">
    Customized Services
  </div>
 <ul class="customize-ul">
   <li>
     Provide components
   </li>
   <li>
     Concentration
   </li>
   <li>
     Stroma
   </li>
   <li>
     Volume
   </li>
 </ul>
  <div class="customize-kf">
    <a href="https://tb.53kf.com/code/client/670c56f26e85ec66df9d068b5c2cd2531/1">
      <img src="https://weiyeglobal.oss-accelerate.aliyuncs.com/News/f5f547118ffa452696fadb859c5254c2.png" alt="">
      <span class="consulting">Consulting</span>
    </a>
  </div>
</div>
</template>

<script lang="ts">

//  utils
import api from "@/api/axios";
import Toast from "@/components/UI/Toast/";
import { nextTick } from "q";
import $ from "jquery";

</script>

<style lang="scss" scoped>
.customize-div{
  width: 100%;
padding:120px 0;
  height: 93.2vh;
  background: rgb(0,0,0,0.8);
  color: white;
  font-size: 16px;
}
  .customize-title{
    font-weight: bold;
    font-size: 24px;
    text-align: center;
  }
  .customize-ul{

    width: 220px;
    margin: 20px auto;
  }
  .customize-kf{
    width: 265px;
    margin: 40px auto;
  }
  .consulting{
    display: inline-block;
    text-align: center;
    border: 0.02667rem solid #fff;
    color: #fff;
    margin-left: 0.1rem;
    padding: 4px 15px;
    position: relative;
    left: 10px;
    bottom: 7px;
  }
  li{
    list-style: disc;
   margin:  10px 0;
  }
</style>
